import { defineComponent } from 'vue';
export default defineComponent({
    name: 'PrivacyPolicy',
    emits: ['title'],
    setup(_, { emit }) {
        const title = 'Hanwha Solutions Corporation - Privacy Policy';
        const techSupportEmail_EU = 'qhome.service@hanwha-qcells.com';
        const processorEmail_EU = 'q.home@e-service48.de';
        const techSupportEmail_AU = 'qhome.au@q-cells.com';
        const processorEmail_AU = 'support@qhome.email';
        const webUrl = 'https://au.qommand.qcells.com';
        const hanwhaQcellsEmail = 'hanwha.qcells.adm@gmail.com';
        emit('title', title);
        return {
            title,
            techSupportEmail_EU,
            processorEmail_EU,
            techSupportEmail_AU,
            processorEmail_AU,
            webUrl,
            hanwhaQcellsEmail,
        };
    },
});
